const hostname = window && window.location && window.location.hostname;
let backendHost;
console.log(hostname);
if(hostname === 'localhost' || hostname === 'university') {
	backendHost = '//university'+':8080';  
	//backendHost = 'https://siakad3.iain-tulungagung.ac.id';
}else{ 
	backendHost = 'https://'+hostname;	
}
export const API_HOST = backendHost;

export function getHeader(){
	//var token = localStorage.siakadToken?localStorage.siakadToken:'';
	var token = localStorage.AuthToken?localStorage.AuthToken:'';
	return {
		"X-Requested-With": "XMLHttpRequest",
		"Content-Type": "application/json;charset=UTF-8",
		"Authorization":"Bearer "+token
	};
}

export const DATE_FORMAT = 'D MMMM YYYY';

export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
