import Loadable from "react-loadable";
import Loader from "./Loader"

import Dashboard from "views/Dashboard.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";

const LoadableUserList= Loadable({
  loader: () => import('views/Pages/User/UserList'),
  loading: Loader,
});
const LoadableUserImages= Loadable({
  loader: () => import('views/Pages/User/UserImages'),
  loading: Loader,
});
var routes = [
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard
  },
  {
	path: "/login-page",
	layout: "/auth",
	name: "Login Page",
	mini: "LP",
	component: LoginPage,
	redirect:true
  },
  {
	path: "/users/images/:id/",
	layout: "/admin",
	name: "User Images",
	mini: "B",
	component: LoadableUserImages,
	redirect:true
  },
   {
	path: "/users",
	layout: "/admin",
	name: "Users",
	icon: "pe-7s-users",
	component: LoadableUserList
  },
  
];
export default routes;
