/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";

import $ from 'jquery'
import {API_HOST, getHeader} from '../../Config';


class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      username: '',
      password: ''
    };
  }
  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }
  handleChange = (key) => {
		return function (e) {
			var state = {};
			state[key] = e.target.value;
			this.setState(state);
		}.bind(this);
	};
  submit = (e) =>{
	e.preventDefault();
	const datax = {username:this.state.username, password:this.state.password};
	
	$.ajax({		
		type: 'POST',
		crossDomain: true,
		url: `${API_HOST}/auth/admin-login`,		
		data: JSON.stringify(datax),
		headers: getHeader(),
		success(data) {
			console.log(data);
			if(data.token){
				localStorage.AuthToken = data.token;
				localStorage.username = data.username;
				window.location.href = '/admin/dashboard' ;
			}else{
				alert('Failed to login');
			}
		},
		error(data) { // if error occured
			console.log(data);
			//alert(data.responseText);
			try{
				const responseText = $.parseJSON(data.responseText);
				if(responseText.error){
					alert(responseText.error.message);					
				}else{
					alert(data.responseText);
				}
			}catch(ex){
				console.log(data);
			}	
		}
	})
	.done(data => {
		
	})
  }
  render() {
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form method="post" action="/login" onSubmit={this.submit}>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Login"
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>Username</ControlLabel>
                      <FormControl placeholder="Userame" type="text" name="username" value={this.state.username} onChange={this.handleChange('username')} 
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl placeholder="Password" type="password" autocomplete="on" name="password"
                      value={this.state.password} onChange={this.handleChange('password')} 
                      />
                    </FormGroup>
                   
                  </div>
                }
                legend={
                  <Button bsStyle="info" fill wd onClick={this.submit} type="submit">
                    Login
                  </Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default LoginPage;
