/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Switch, Route,Redirect } from "react-router-dom";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// react component that creates notifications (like some alerts with messages)
import NotificationSystem from "react-notification-system";

import Sidebar from "components/Sidebar/Sidebar.jsx";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";


import image from "assets/img/full-screen-image-3.jpg";

// dinamically create dashboard routes
import routes from "routes.js";
import history from "history";

// style for notifications
import { style } from "variables/Variables.jsx";
import $ from 'jquery'
import {API_HOST,getHeader} from '../Config';

var ps;
let lastTime = 0;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      image: image,
      color: "black",
      hasImage: true,
      navbar: false,
      mini: false,
      fixedClasses: "dropdown show-dropdown open",
      authed:this.loggedIn(),
    };
  }
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    
  }
  
  loggedIn() {
	  if(window.location.href.indexOf("/admin/reset")!==-1){
		  return false;
	  }
	  //get token
	  const ret = !!localStorage.AuthToken;
	  if ( Math.floor((new Date() - lastTime)/60000) < 2 ) {
	  }else {
    	//more than 2 minutes
	    lastTime =  new Date();
	     //check whether expired
	     $.ajax({		
			type: 'GET',
			crossDomain: true,
			url: `${API_HOST}/auth/verify`,				
			headers: getHeader(),
			success(data) {			
				console.log(data);
				if(data.status==1){
					
				}else{
					alert('You are not authenticated. Please re-login');
					localStorage.clear();
					
				}
			},
			error(data) { // if error occured
				if(data.responseText){
					if(data.responseText=='Bad Authorization Header: Bearer' || data.responseText=='Invalid Token'){
						localStorage.clear();
						//console.log(window.location.href);
						
					}
					try{
						const responseText = $.parseJSON(data.responseText);			
						console.log(responseText);
						if(responseText.error){
							
							localStorage.clear();
							
						}
					}catch(e){
						console.log(data);
					}
				}else{
					console.log(data);
				}
				
			}
		})
		.done(data => {
			
		})
	  }
	  
    return ret;
  }
  componentWillUnmount() {
    //if (navigator.platform.indexOf("Win") > -1) {
      //ps.destroy();
    //}
  }
  componentDidUpdate(e) {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
    if (
      window.innerWidth < 993 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
    window.notify = this.notify;      
	window.handleAjaxError = this.handleAjaxError;
	window.redirect = this.redirect;
  }
  // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
  handleNotificationClick = position => {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
          every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15
    });
  };
  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleHasImage = hasImage => {
    this.setState({ hasImage: hasImage });
  };
  handleNavbarClick = navbar => {
    this.setState({ navbar: navbar });
  };
  handleMiniClick = () => {
    this.setState({ mini: !this.state.mini });
    document.body.classList.toggle("sidebar-mini");
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  getRoutes = routes => {
	if(!this.state.authed){
		return (<Redirect to='/auth/login-page' />);
	}
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route authed={this.state.authed} 
            path={prop.layout + prop.path}
            key={key}
            render={routeProps => (
              <prop.component
                {...routeProps}
                handleClick={this.handleNotificationClick}
              />
            )}
          />
        );
      } else {
        return null;
      }
    });
  };
  redirect = (path) => {	
		
	//browserHistory.push('/admin/empty');
	//browserHistory.push('/admin'+path);
	
		window.location.href = '/admin'+path;
	}
	notify = (text, type) => {
		var prm = {message: text, level: type, position:'tc'};		
		this.state._notificationSystem.addNotification(prm);
	}
	handleAjaxError = (data) => {
		var msg = '';
		console.log(data);
		if (data.statusText === 'abort') {
			return;
		}
		if (data.status === 0) {
			 msg = 'Not connected. Verify your network.';
		}else{
			if(data.hasOwnProperty('error')){
				msg = data.error.message;
			}else if(data.hasOwnProperty('responseText')){
				try{
					var responseText = $.parseJSON(data.responseText);
					if(responseText.error){				
						if(responseText.error.title=='Exception'){
						}							
						msg = responseText.error.message;
					}else{
						msg = data.responseText;
					}
				}catch(err){
					if(data.responseText==="You don't have access" || data.responseText==="Invalid Token"){
						this.setState({loggedIn:false},()=>{
							window.redirect('/logout');
						});						
					}else{
						alert('failed to parse json: ' +data.responseText);
					}
				}
			}else{
				msg = data.statusText;
			}	
		}
		if(msg!=''){
			this.notify(msg,'error');
		}
		
	}
  render() {
    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar
          {...this.props}
          image={this.state.image}
          color={this.state.color}
          hasImage={this.state.hasImage}
          mini={this.state.mini}
        />
        <div
          className={
            "main-panel" +
            (this.props.location.pathname === "/maps/full-screen-maps"
              ? " main-panel-maps"
              : "")
          }
          ref="mainPanel"
        >
          <AdminNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            navbar={this.state.navbar}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          <Footer fluid />
          
        </div>
      </div>
    );
  }
}

export default Dashboard;
